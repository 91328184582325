import React from 'react';
import { Button, ButtonType } from '@components/Button';
import { Row, StyledIconImg } from '@styles/common';
import { gridiconsNoticeIcon } from '@styles/imgMapping';
import { AppError } from '@/providers/pageProvider';
import { StyledButton, StyledMessage, StyledModal, StyledTitle } from './Styles';

type Props = {
  title: string;
  opened: boolean;
  onClose: () => void;
  error: AppError;
};

const ErrorModal = ({ title, opened, onClose, error }: Props) => {
  const handleClose = () => {
    onClose();
  };

  const errorCode = `${error?.code}: ` || '';
  const errorMessage = error?.message || 'Something went wrong';

  return (
    <StyledModal hasBackDrop opened={opened} onClose={handleClose} width="450" disabledCloseButton>
      <Row justify="space-between" align="flex-start">
        <div>
          <StyledTitle>{title}</StyledTitle>
          <StyledMessage>{`${errorCode}${errorMessage}`}</StyledMessage>
        </div>
        <StyledIconImg src={gridiconsNoticeIcon} alt="error" width="40px" />
      </Row>
      <Row justify="center">
        <StyledButton buttonType={ButtonType.CUSTOM} width="87px" onClick={handleClose} mt="32px">
          OK
        </StyledButton>
      </Row>
    </StyledModal>
  );
};

export default ErrorModal;
