import { Button } from '@mui/material';
import styled from 'styled-components';
import { fontStyle, size } from '@styles/common';
import { SM_AND_UP_QUERY } from '@/breakpoints';

export const StyledWrapper = styled.div`
  margin: 0 auto;
  text-align: center;
  width: 100%;

  & > a {
    text-decoration: none;
  }

  @media ${SM_AND_UP_QUERY} {
    width: auto;
  }
`;

export const StyledImage = styled.img`
  ${size('100px', '100px')};
  margin-bottom: 20px;

  @media ${SM_AND_UP_QUERY} {
    ${size('160px', '160px')};
    margin-bottom: 24px;
  }
`;

export const StyledText = styled.div`
  ${fontStyle('14px')};
  line-height: 17px;
  font-family: 'Inter', sans-serif;
  color: ${({ theme }) => theme.colors.white};

  @media ${SM_AND_UP_QUERY} {
    ${fontStyle('20px')};
    line-height: 30px;
  }
`;

export const StyledButton = styled(Button)`
  &&& {
    ${size('100%', '52px')};
    ${fontStyle('14px', '700')};
    padding: 12px 0;
    background-color: ${({ theme }) => theme.colors.darkDream};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.white};
    text-transform: none;
    margin-top: 20px;
    box-shadow: 0px 1px 1px -2px rgba(0, 0, 0, 0.2);
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.12));

    :hover {
      background-color: ${({ theme }) => theme.colors.brightDream};
    }

    @media ${SM_AND_UP_QUERY} {
      margin-top: 24px;
      ${size('218px', '52px')};
      ${fontStyle('20px', '700')};
    }
  }
`;
