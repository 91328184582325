import styled from 'styled-components';
import { size } from '@styles/common';
import { SM_AND_UP_QUERY } from '@/breakpoints';

export const StyledWrapper = styled.div`
  margin: 0 auto;
  text-align: center;

  & > div {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const StyledWarningImage = styled.img`
  ${size('80px', '80px')};
  margin-bottom: 32px;

  @media ${SM_AND_UP_QUERY} {
    ${size('100px', '100px')};
    margin-bottom: 34px;
  }
`;
