import React from 'react';
import keys from 'lodash/keys';
import { powerByRedreamerBlackIcon, powerByRedreamerWhiteIcon } from '@styles/imgMapping';
import {
  StyledBottomRow,
  StyledContainer,
  StyledContent,
  StyledDivide,
  StyledFooterText,
  StyledFooterTextContainer,
  StyledIcon,
  StyledImage,
  StyledPosition,
  StyledSocialMeidaRow,
  StyledTopContainer,
} from './Styles';
import { Mode, TradeMark } from './type';

interface ICustomConfig {
  [componentKey: string]: {
    [fieldKey: string]: {
      isShow?: boolean;
      link?: string;
      imgSrc?: string;
      imgHoverSrc?: string;
      width?: number;
      mobileWidth?: number;
    };
  };
}

type Props = {
  customConfig: ICustomConfig;
  mode?: Mode;
  forSales?: boolean;
  socialIconColor?: string;
  tradeMarkType?: TradeMark;
};

const date = new Date();
const year = date.getFullYear();
const applySocialMedias = (customConfig: ICustomConfig) =>
  keys(customConfig.footer).filter((key) => customConfig.footer[key].isShow);

const Footer = ({
  customConfig,
  mode = Mode.NORMAL,
  forSales,
  socialIconColor,
  tradeMarkType = TradeMark.BLACK,
}: Props) => (
  <StyledPosition>
    <StyledContainer mode={mode}>
      <StyledTopContainer justify="space-between">
        <StyledContent justify="center">
          <a rel="noreferrer" href={customConfig.footer.logo.link} target="_blank">
            <StyledImage
              src={customConfig.footer.logo.imgSrc}
              width={customConfig.footer.logo?.width || 100}
              mobileWidth={customConfig.footer.logo?.mobileWidth || 80}
            />
          </a>
          {customConfig.footer?.apiDocs && (
            <StyledFooterTextContainer>
              <StyledFooterText>
                You can design for yourself using the 🔗
                <a href={customConfig.footer.apiDocs.link} target="_blank" rel="noreferrer">
                  API document
                </a>
              </StyledFooterText>
              <StyledFooterText>{`Copyright © ${year}  RE:DREAMER Lab. All rights reserved.`}</StyledFooterText>
            </StyledFooterTextContainer>
          )}
        </StyledContent>
        <StyledSocialMeidaRow>
          {applySocialMedias(customConfig).map((key) => {
            const socialMeida = customConfig.footer[key];
            return (
              <a rel="noreferrer" href={socialMeida.link} target="_blank" key={key}>
                <StyledIcon src={socialMeida.imgSrc} mode={mode} socialIconColor={socialIconColor} />
              </a>
            );
          })}
        </StyledSocialMeidaRow>
      </StyledTopContainer>
      {forSales && (
        <div>
          <StyledDivide />
          <StyledBottomRow>
            <StyledFooterText mode={mode}>
              {`Copyright © ${year}  RE:DREAMER Lab. All rights reserved.`}
            </StyledFooterText>
            <a rel="noreferrer" href="https://www.redreamer.io/" target="_blank">
              <StyledImage
                src={tradeMarkType === TradeMark.BLACK ? powerByRedreamerBlackIcon : powerByRedreamerWhiteIcon}
                width={121}
              />
            </a>
          </StyledBottomRow>
        </div>
      )}
    </StyledContainer>
  </StyledPosition>
);

export default Footer;
