import { DiscordIcon, FacebookIcon, InstagramIcon, TwitterIcon, homeLogoImage } from '@styles/imgMapping';

interface ICustomConfig {
  [componentKey: string]: {
    [fieldKey: string]: {
      isShow?: boolean;
      link?: string;
      imgSrc?: string;
      imgHoverSrc?: string;
    };
  };
}

export default {
  footer: {
    logo: {
      link: 'https://www.yuliverse.com/',
      imgSrc: homeLogoImage,
      width: 194,
      mobileWidth: 194,
    },
    twitter: {
      isShow: true,
      link: 'https://twitter.com/TheYuliverse',
      imgSrc: TwitterIcon,
    },
    discord: {
      isShow: true,
      link: 'https://discord.com/invite/Yuliverseofficial',
      imgSrc: DiscordIcon,
    },
  },
} as ICustomConfig;
