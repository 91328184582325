import { useCallback, useState } from 'react';
import { functions } from '@/firebaseConfig';
import { httpsCallable } from 'firebase/functions';

export interface IGetNftByUtilityCollectionPayload {
  type: string;
  chainId: number;
  utilityCollectionId: string;
}

export interface IUtilityCollectionNftById {
  [utilityCollectionId: string]: {
    [tokenId: string]: string;
  };
}

function useGetNFTByUtilityCollectionId() {
  const [loading, setLoading] = useState(false);
  const getNFTsCallable = useCallback(
    (params: IGetNftByUtilityCollectionPayload) => httpsCallable(functions, 'getNFTs')(params),
    [],
  );
  const getNFTsByUtilityCollectionId = useCallback(
    async (payload: IGetNftByUtilityCollectionPayload): Promise<IUtilityCollectionNftById> => {
      setLoading(true);
      try {
        const response: any = (await getNFTsCallable(payload)).data;
        setLoading(false);
        const data = response.data as IUtilityCollectionNftById;
        return Promise.resolve(data);
      } catch (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    },
    [getNFTsCallable],
  );

  return {
    getNFTsByUtilityCollectionId,
    loadingGetNFTsByUtilityCollectionId: loading,
  };
}

export default useGetNFTByUtilityCollectionId;
