import React, { createContext, useContext } from 'react';
import { Mode, Props } from './type';

interface ContextType {
  children: React.ReactNode;
}

type NavbarType = Props & ContextType;

const NavbarContext = createContext<NavbarType>({
  isMainnet: false,
  currentChainType: '',
  switchNetworks: [],
  walletAddress: '',
  hideNetwork: false,
  mode: Mode.LIGHT,
  onResetAuth: () => {},
  onLogoClick: () => {},
  onSwitchChainRedirect: () => {},
  onLogError: () => {},
  children: React.createElement('div'),
});

const Provider = (props: NavbarType) => {
  const contexts = {
    ...props,
  };

  const { children } = props;

  return <NavbarContext.Provider value={contexts}>{children}</NavbarContext.Provider>;
};

const useNavbarContext = () => useContext(NavbarContext);

const NavbarProvider = Provider;

export { NavbarProvider };

export default useNavbarContext;
