import React, { createContext, useContext } from 'react';
import { Web3ReactProvider } from '@web3-react/core';

type Props = {
  connectors: any;
  children: React.ReactNode;
};

interface ContextType {}

const WalletContext = createContext<ContextType>({});

const Provider = ({ connectors, children }: Props) => {
  const contexts = {};

  return (
    <Web3ReactProvider connectors={connectors}>
      <WalletContext.Provider value={contexts}>{children}</WalletContext.Provider>
    </Web3ReactProvider>
  );
};

const useWalletContext = () => useContext(WalletContext);

const WalletProvider = Provider;

export { WalletProvider };

export default useWalletContext;
