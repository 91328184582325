import styled from 'styled-components';
import { Button } from '@components/Button';
import { Modal } from '@components/Modal';
import { fontStyle } from '@styles/common';

export const StyledTitle = styled.div`
  ${fontStyle('24px', '600')};
  font-family: 'Poppins', sans-serif;
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledMessage = styled.div`
  ${fontStyle('16px', '700')};
  margin-top: 8px;
  word-break: break-word;
  font-family: 'Poppins', sans-serif;
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledModal = styled(Modal)`
  & > div:last-child {
    border: solid 5px ${({ theme }) => theme.colors.darkDream};
    padding: 48px;
    background: ${({ theme }) => theme.colors.black};
  }
`;

export const StyledButton = styled(Button)`
  &&& {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.darkDream};
    border: none;
  }
`;
