/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useHistory } from 'react-router';
import { ConnectButton } from '@components/ConnectButton';
import { Footer, Mode } from '@components/Footer';
import { SelectWallet } from '@configs/evmConnecters';
import useApplicationContext from '@providers/applicationContext';
import { useAuthContext } from '@providers/authProvider';
import customConfig from '@styles/customConfig';
import { walletConnectImg, walletJoyIdImg, walletMetamaskImg, walletOKXImg } from '@styles/imgMapping';
import { ErrorModal } from '@/components/ErrorModal';
import { useJoyIdWallet } from '@/hooks/useJoyIdWallet';
import { useOKXWallet } from '@/hooks/useOKXWallet';
import { AppError } from '@/providers/pageProvider';
import { BindingModal } from './BindingModal';
import { Logo } from './Logo';
import {
  StyledAutoSpace,
  StyledConnectDescription,
  StyledConnectTitle,
  StyledContainer,
  StyledDisconnectButton,
  StyledImg,
  StyledRibbonContainer,
  StyledSignContainer,
  StyledWrapper,
} from './Styles';

const Home = () => {
  const history = useHistory();

  const { account } = useWeb3React();
  const { okxAddress } = useOKXWallet();
  const { joyIdAddress } = useJoyIdWallet();

  const { selectWallet, setTriggerSignWallet, appError, setAppError, deactivateWallet, isJoyIdConnected } =
    useApplicationContext();
  const { accessToken, resetAuth } = useAuthContext();

  const handleSignButton = () => {
    setTriggerSignWallet(true);
  };

  const handleUnbind = async () => {
    await deactivateWallet();
    resetAuth();
    setTriggerSignWallet(false);
  };

  const handleGoUtilityCollection = () => {
    history.push('/campaigns');
  };

  const getAddress = () => account;

  const address = getAddress();

  const getWalletImage = () => {
    switch (selectWallet) {
      case SelectWallet.META_MASK:
        return walletMetamaskImg;
      case SelectWallet.WALLET_CONNECT:
        return walletConnectImg;
      case SelectWallet.JOY_ID:
        return walletJoyIdImg;
      case SelectWallet.OKX:
        return walletOKXImg;
      default:
        return '';
    }
  };

  const convertAddressToSimple = () => {
    let addr = address;
    if (joyIdAddress) {
      addr = joyIdAddress;
    }
    if (okxAddress) {
      addr = okxAddress;
    }

    const prefixStr = addr?.substring(0, 6) || '';
    const suffixStr = addr?.substring(addr.length - 4) || '';
    return `${prefixStr}...${suffixStr}`;
  };

  const showWallets = () => {
    const isEvm = !accessToken && !address;
    const joyID = !accessToken && !isJoyIdConnected;
    const okx = !accessToken && !okxAddress;
    return isEvm && joyID && okx;
  };

  const showSignModal = () => {
    const isEvmSign = !accessToken && address;
    const joyIDSign = !accessToken && isJoyIdConnected;
    const okxSign = !accessToken && okxAddress;
    return isEvmSign || joyIDSign || okxSign;
  };

  return (
    <StyledContainer>
      <StyledAutoSpace>
        <Logo />
        {showWallets() && <BindingModal />}
        {showSignModal() && (
          <StyledWrapper>
            <StyledImg src={getWalletImage()} selectWallet={selectWallet} />
            <StyledConnectDescription>{convertAddressToSimple()}</StyledConnectDescription>
            <StyledConnectTitle>Wallet Connected</StyledConnectTitle>
            <StyledConnectDescription>Sign the message with your wallet in order to continue.</StyledConnectDescription>
            <ConnectButton name="Sign" onClick={handleSignButton} />
            <StyledDisconnectButton onClick={handleUnbind}>Disconnect</StyledDisconnectButton>
          </StyledWrapper>
        )}
        {accessToken && (
          <StyledSignContainer>
            <ConnectButton name="Information" onClick={handleGoUtilityCollection} />
          </StyledSignContainer>
        )}
      </StyledAutoSpace>
      <Footer forSales mode={Mode.DARK} customConfig={customConfig} />
      <ErrorModal
        title="Error"
        opened={!!appError}
        onClose={() => setAppError(undefined)}
        error={appError as AppError}
      />
    </StyledContainer>
  );
};

export default Home;
