export enum Mode {
  DARK = 'dark',
  LIGHT = 'light',
}

export type Props = {
  isMainnet: boolean;
  currentChainType: string;
  logoImgUrl?: string;
  switchNetworks: IGetSwitchNetworks[];
  hideNetwork?: boolean;
  hideUnbindButton?: boolean;
  walletAddress?: string;
  mode?: Mode;
  onResetAuth: () => void;
  onLogoClick?: () => void;
  onSwitchChainRedirect: () => void;
  onLogError: (error: Error, account?: string | null) => void;
};

export interface IGetSwitchNetworks {
  id: number;
  url: string;
  chainName: string;
  nativeCurrency:
    | {
        name: string;
        symbol: string;
        decimals: number;
      }
    | {
        name: string;
        symbol: string;
        decimals: number;
      }
    | undefined;
  icon: string;
}
