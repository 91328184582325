import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import ReactMarkdown from 'react-markdown';
import { defaultERC721ABI } from '@/abi/defaultERC721ABI';
import { useGetMetadata } from '@/api/getMetadata';
import { IUtilityCollectionById } from '@/api/getUtilityCollection';
import { polygonProvider } from '@/configs/evmConnecters';
import { StyledDesktopRender } from '@/styles/common';
import { convertIPFS } from '@/utils/ipfs';
import {
  StyledDescription,
  StyledDescriptionEleRow,
  StyledMerchantTtile,
  StyledRedeemableNft,
  StyledRedeemableWrapper,
  StyledWrapper,
} from './Styles';

type Props = {
  utilityCollection?: IUtilityCollectionById;
};

type Metadata = {
  name?: string;
  image?: string;
  imageData?: string;
  attributes?: any[];
  description?: string;
  externalUrl?: string;
  backgroundColor?: string;
  animationUrl?: string;
  youtubeUrl?: string;
  notSupportRedeem?: boolean;
  contractAddress?: string;
};

const NOT_SUPPORT = 'NOT_SUPPORT';

const UtilityCollectionDescription = ({ utilityCollection }: Props) => {
  const [metas, setMetas] = useState<Metadata[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { provider } = useWeb3React();
  const { getMetadata } = useGetMetadata();

  // 1. Get metadata from first token
  // 2. Get imageURI from metadata
  // *  Exclude not supported contract
  useEffect(() => {
    if (utilityCollection?.contractAddresses && utilityCollection.contractAddresses.length > 0) {
      setLoading(true);

      let usageProvider: any;
      if (!provider) {
        usageProvider = polygonProvider;
      } else {
        usageProvider = provider!.getSigner(0);
      }

      const promises = Promise.allSettled(
        utilityCollection.contractAddresses.map(async (a) => {
          const c = new ethers.Contract(a, defaultERC721ABI, usageProvider);
          const firstTokenId = await c.tokenByIndex(0);
          return await c.tokenURI(Number(firstTokenId));
        }),
      );

      const metaPromises = promises.then((promiseSettleResults) => {
        const metadataURIs = promiseSettleResults.map((p) => {
          if (p.status === 'fulfilled') return convertIPFS(p.value);
          if (p.status === 'rejected') return NOT_SUPPORT;
          return '';
        });

        const promises = Promise.allSettled(
          metadataURIs.map(async (metadataURI) => {
            if (!metadataURI || metadataURI === NOT_SUPPORT) return { notSupportRedeem: true };
            if (metadataURI) {
              try {
                const metadata = await getMetadata({
                  requestConfigs: {
                    baseURL: metadataURI,
                    isExternal: true,
                  },
                });
                return metadata || {};
              } catch (error) {
                return { notSupportRedeem: true };
              }
            }
            return {};
          }),
        );

        return promises.then((promiseSettleResults) => {
          const metadatas: Metadata[] = promiseSettleResults.map((p) => {
            if (p.status === 'fulfilled') {
              const convertedImage = convertIPFS(p.value?.image);
              return { ...p.value, image: convertedImage };
            }
            return {};
          });
          return metadatas;
        });
      });

      metaPromises.then((ms) => {
        const metadataWithContractAddresses = ms
          .filter((m) => !m.notSupportRedeem)
          .map((m, i) => ({
            ...m,
            contractAddress: utilityCollection.contractAddresses[i],
          }));
        setMetas(metadataWithContractAddresses);
        setLoading(false);
      });
    }
  }, [utilityCollection, provider]);

  const hasMetaData = metas.length > 0;
  const merchantNamesStr = metas
    .map((m) => m.name)
    .toString()
    .replace(',', '、');

  return (
    <div>
      <StyledDesktopRender>
        <StyledDescription>
          <ReactMarkdown linkTarget="_blank">{utilityCollection?.description || ''}</ReactMarkdown>
        </StyledDescription>
      </StyledDesktopRender>
      {hasMetaData && (
        <StyledWrapper>
          <StyledDescriptionEleRow>
            <StyledMerchantTtile marginTop="20px">Merchant Name</StyledMerchantTtile>
            <div>{merchantNamesStr}</div>
          </StyledDescriptionEleRow>
          <StyledDescriptionEleRow>
            <StyledMerchantTtile marginTop="40px">Redeemable NFTs</StyledMerchantTtile>
            <StyledRedeemableWrapper>
              {metas.map((meta, i) => (
                <StyledRedeemableNft
                  key={meta.name}
                  alt="nft"
                  title={meta?.name || meta?.description || meta?.contractAddress || '-'}
                  src={meta?.image}
                />
              ))}
            </StyledRedeemableWrapper>
          </StyledDescriptionEleRow>
        </StyledWrapper>
      )}
    </div>
  );
};

export default UtilityCollectionDescription;
