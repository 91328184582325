import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import axios from 'axios';
import { Loading } from '@components/Loading';
import { ghostFrightenedIcon } from '@styles/imgMapping';
import { Logger } from '@/api/logger';
import { ErrorModal } from '@/components/ErrorModal';
import { SuccessModal } from '@/components/SuccessModal';
import { useJoyIdWallet } from '@/hooks/useJoyIdWallet';
import { useOKXWallet } from '@/hooks/useOKXWallet';
import loadingEffect from '@/loading.json';
import { AppError } from '@/providers/pageProvider';
import { StyledButton, StyledImage, StyledText, StyledWrapper } from './Style';

type Props = {
  utilityCollectionId: string;
  onRefresh: () => void;
};

const EventIsEmpty = ({ utilityCollectionId, onRefresh }: Props) => {
  const { account, chainId: injectionChainId } = useWeb3React();
  const { joyIdAddress, joyIdchainId } = useJoyIdWallet();
  const { okxAddress, okxChainId } = useOKXWallet();

  const [notice, setNotice] = useState<{ title: string; option: AppError } | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showDropNft, setShowDropNft] = useState<boolean>(false);

  const address = account || joyIdAddress || okxAddress;
  const chainId = injectionChainId || joyIdchainId || okxChainId;

  const getFreeMint = async () => {
    try {
      setLoading(true);
      await axios.post('https://api-zogq3k52ja-uc.a.run.app/api/v1/hongKongDirlPass/claim', {
        address,
        chainId,
      });
      // setNotice({
      //   title: 'Success',
      //   option: { message: 'Please refresh to check the NFT.' } as AppError,
      // });
      onRefresh();
    } catch (error: any) {
      if (error.response) {
        Logger.error({ error });
        const alreadyGetNft = error.response.data?.message === `${address} is not allowed to claim.`;
        setNotice({
          title: 'Error',
          option: alreadyGetNft ? { message: 'NFT can only be claimed once.' } : error.response.data,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getWalletStatus = async () => {
      try {
        const { data } = await axios.get(
          `https://api-zogq3k52ja-uc.a.run.app/api/v1/hongKongDirlPass/isClaimed?address=${address}&chainId=${chainId}`,
        );
        if (!data?.isClaimed) {
          setShowDropNft(true);
        }
      } catch (error: any) {
        setShowDropNft(false);
      }
    };

    if (chainId !== 1030) {
      setShowDropNft(false);
    } else {
      getWalletStatus();
    }
  }, [address, chainId]);

  if (showDropNft) {
    return (
      <StyledWrapper>
        <Loading effect={loadingEffect} isLoading={loading} />
        <StyledImage src={ghostFrightenedIcon} />
        <StyledText>Don’t see your NFT, get your NFT?</StyledText>
        <StyledText>Import NFTs</StyledText>
        <StyledButton onClick={getFreeMint}>Request an NFT</StyledButton>
        <ErrorModal
          title={notice?.title || ''}
          opened={notice?.title === 'Error'}
          onClose={() => setNotice(undefined)}
          error={notice?.option || ({ message: '' } as AppError)}
        />
        <SuccessModal
          title={notice?.title || ''}
          opened={notice?.title === 'Success'}
          onClose={() => setNotice(undefined)}
          message={notice?.option.message || ''}
        />
      </StyledWrapper>
    );
  }

  return (
    <StyledWrapper>
      <StyledImage src={ghostFrightenedIcon} />
      <StyledText>Don’t see your NFT, get your NFT?</StyledText>
      <StyledText>Import NFTs</StyledText>
      <a href="https://hongkongdirlpass.domin.foundation/" target="_blank" rel="noreferrer">
        <StyledButton>Get NFT</StyledButton>
      </a>
    </StyledWrapper>
  );
};

export default EventIsEmpty;
