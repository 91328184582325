import { Method, RequestConfig } from '../promise-request';

export type Params = {
  requestConfigs: RequestConfig;
};

const caller = (data: Params) => {
  const { requestConfigs } = data;
  const url = requestConfigs.baseURL;
  return {
    method: Method.GET,
    url,
  };
};

export default caller;
