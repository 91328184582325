import React, { createContext, useContext, useState } from 'react';
import isNil from 'lodash/isNil';

export interface AppError extends Error {
  code: string;
  error: string;
}

type Context = {
  error: AppError | undefined;
  updateError: (error?: AppError) => void;
};

const defaultContext: Context = {
  error: undefined,
  updateError: () => {},
};

const PageContext = createContext(defaultContext);

const usePageProvider = () => {
  const [error, setError] = useState<AppError | undefined>(undefined);

  const updateError = (error?: AppError) => {
    if (!isNil(error)) {
      setError(error);
    } else {
      setError(undefined);
    }
  };

  return {
    error,
    updateError,
  };
};

export const PageProvider = ({ children }: any) => {
  const context = usePageProvider();
  return <PageContext.Provider value={context}>{children}</PageContext.Provider>;
};

export const usePageContext = () => useContext(PageContext);
