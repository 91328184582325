import React from 'react';
import QRCode from 'qrcode.react';
import ReactMarkdown from 'react-markdown';
import { ModalMode } from '@components/Modal';
import { StyledIconImg } from '@styles/common';
import { refreshRoundedIcon } from '@styles/imgMapping';
import theme from '@styles/theme';
import { AfterRedeemedType } from '@/api/redeem/type';
import {
  StyledDescription,
  StyledModal,
  StyledQrCode,
  StyledQrCodeWrapper,
  StyledQrStringWrapper,
  StyledRefreshBackground,
  StyledTitle,
  StyledWrapper,
} from './Styles';

const QRCODE_SIZE = 200;

type Props = {
  opened: boolean;
  qrCode: string;
  redemptionEvent: { eventName: string; eventDescription: string };
  redemptionType: AfterRedeemedType;
  onClose: () => void;
};

const QrCodeModal = ({ qrCode, redemptionType, redemptionEvent, opened, onClose }: Props) => {
  const showRefreshControl = false;

  return (
    <StyledModal
      hasBackDrop
      backgroundColor={theme.colors.white}
      closeColor={theme.colors.darkDream}
      mode={ModalMode.QR_CODE}
      opened={opened}
      onClose={onClose}
    >
      <StyledWrapper>
        {qrCode && redemptionType === AfterRedeemedType.QR_CODE && (
          <StyledQrCodeWrapper>
            <StyledQrCode>
              <QRCode id="passport" value={qrCode} width="100%" size={QRCODE_SIZE} level="L" />
            </StyledQrCode>
            {showRefreshControl && (
              <StyledRefreshBackground justify="center">
                <StyledIconImg src={refreshRoundedIcon} width="46px" />
              </StyledRefreshBackground>
            )}
          </StyledQrCodeWrapper>
        )}
        {qrCode && redemptionType === AfterRedeemedType.QR_STRING && (
          <StyledQrStringWrapper>
            <ReactMarkdown linkTarget="_blank">{qrCode}</ReactMarkdown>
          </StyledQrStringWrapper>
        )}
        <StyledTitle hideMargin={!qrCode}>{redemptionEvent.eventName}</StyledTitle>
        <StyledDescription>
          <ReactMarkdown linkTarget="_blank">{redemptionEvent.eventDescription}</ReactMarkdown>
        </StyledDescription>
      </StyledWrapper>
    </StyledModal>
  );
};

export default QrCodeModal;
