/* eslint-disable react/forbid-prop-types */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { disconnect, initConfig, signTypedData as joyIdSignTypedData } from '@joyid/evm';
import { useWeb3React } from '@web3-react/core';
import get from 'lodash/get';
import { useHistory, useLocation } from 'react-router';
import Web3 from 'web3';
import { Logger } from '@api/logger';
import { SelectWallet, okxHooks, okxWallet } from '@configs/evmConnecters';
import { chainMapping } from '@configs/evmWalletConfigs';
import { useGetSignInMessage } from '@/api/auth/getSignInMessage';
import { useJoyIdWallet } from '@/hooks/useJoyIdWallet';
import { useOKXWallet } from '@/hooks/useOKXWallet';
import { signTypedData } from '@/rpc';
import { red } from '@mui/material/colors';
import { useAuthContext } from './authProvider';
import { AppError } from './pageProvider';

type Props = {
  children: React.ReactNode;
};

interface IChain {
  id: number;
  url: string;
  chainName: string;
  nativeCurrency?: {
    name: string;
    symbol: string;
    decimals: number | string;
  };
}

interface ContextType {
  selectWallet: SelectWallet | undefined;
  setSelectWallet: React.Dispatch<React.SetStateAction<SelectWallet | undefined>>;
  web3?: Web3;
  currentChainType: string;
  isMainnet: boolean;
  contracts?: object;
  addressConfigs?: object;
  tokenNames?: any[];
  triggerSignWallet: boolean;
  setTriggerSignWallet: React.Dispatch<React.SetStateAction<boolean>>;
  appError: AppError | undefined;
  setAppError: React.Dispatch<React.SetStateAction<AppError | undefined>>;
  deactivateWallet: () => Promise<void>;
  handleMetamaskSwitchNetwork: (chain: IChain) => void;
  handleOKXSwitchNetwork: (chain: IChain) => void;
  handleJoyIdSwitchNetwork: (chain: IChain) => void;
  isJoyIdConnected: boolean;
  setIsJoyIdConnected: React.Dispatch<React.SetStateAction<boolean>>;
  redirectPath: string;
}

const ApplicationContext = createContext<ContextType>({
  selectWallet: undefined,
  setSelectWallet: () => { },
  web3: undefined,
  contracts: {},
  addressConfigs: {},
  tokenNames: [],
  currentChainType: '',
  isMainnet: false,
  triggerSignWallet: false,
  setTriggerSignWallet: () => { },
  appError: undefined,
  setAppError: () => { },
  deactivateWallet: () => Promise.resolve(),
  handleMetamaskSwitchNetwork: () => { },
  handleOKXSwitchNetwork: () => { },
  handleJoyIdSwitchNetwork: () => { },
  isJoyIdConnected: false,
  setIsJoyIdConnected: () => { },
  redirectPath: '',
});

const Provider = ({ children }: Props) => {
  const { chainId, account, provider, connector } = useWeb3React();
  const { okxAddress, okxProvider } = useOKXWallet();
  const { joyIdAddress } = useJoyIdWallet();

  const userAddress = (account || '').toLowerCase();
  const { login, resetAuth } = useAuthContext();

  const history = useHistory();
  const location = useLocation();

  const [web3, setWeb3] = useState<Web3>();
  const [triggerSignWallet, setTriggerSignWallet] = useState<boolean>(false);
  const [selectWallet, setSelectWallet] = useState<SelectWallet | undefined>();
  const [appError, setAppError] = useState<AppError | undefined>(undefined);
  const [redirectPath, setRedirectPath] = useState<string>('/');
  const [isJoyIdConnected, setIsJoyIdConnected] = useState<boolean>(false);

  const { getSignInMessage } = useGetSignInMessage();

  const deactivateWallet = async () => {
    if (connector) {
      // metamask
      await connector.resetState();
      localStorage.clear();
      connector.provider = undefined;
      (connector as typeof connector & { eagerConnection: any }).eagerConnection = undefined;

      // okx
      await okxWallet.resetState();
      okxWallet.provider = undefined;
      (okxWallet as any).eagerConnection = undefined;

      // joyid
      setIsJoyIdConnected(false);
    }
  };

  const handleMetamaskSwitchNetwork = async (chain: IChain) => {
    try {
      const { id } = chain;
      await connector.activate(id);
    } catch (error) {
      const { chainName, id, url, nativeCurrency } = chain;
      const hex = `0x${id.toString(16)}`;
      await connector.provider?.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: hex,
            rpcUrls: [url],
            chainName,
            nativeCurrency,
          },
        ],
      });
    }
  };

  const handleOKXSwitchNetwork = async (chain: IChain) => {
    try {
      const { id } = chain;
      await okxWallet.activate(id);
    } catch (error) {
      const { chainName, id, url, nativeCurrency } = chain;
      const hex = `0x${id.toString(16)}`;
      await okxWallet.provider?.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: hex,
            rpcUrls: [url],
            chainName,
            nativeCurrency,
          },
        ],
      });
    }
  };

  const handleJoyIdSwitchNetwork = (chain: IChain) => {
    initConfig({
      network: {
        chainId: chain.id,
        name: chain.chainName,
      },
    });
  };

  const isMainnet = get(chainMapping, [chainId as number, 'network']) === 'mainnet';

  useEffect(() => {
    if (provider) {
      const w = new Web3(provider as any);
      window.web3 = w;
      setWeb3(w);
    }
  }, [provider]);

  useEffect(() => {
    history.replace('/');
  }, [userAddress, history, joyIdAddress, okxAddress]);

  useEffect(() => {
    if (location.pathname !== '/') {
      setRedirectPath(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    // evm login
    if (triggerSignWallet && provider && userAddress) {
      const handleLogin = async () => {
        history.push('/');
        resetAuth();
        try {
          const signInMessage: any = await getSignInMessage({ type: 'evm', address: userAddress });
          const signature = await signTypedData(provider, userAddress, signInMessage);
          login({ type: 'evm', address: userAddress, signature })
            .then(() => {
              history.push(redirectPath === '/' ? '/campaigns' : redirectPath);
            })
            .finally(() => {
              setTriggerSignWallet(false);
            });
        } catch (e) {
          const error = e as AppError;
          Logger.error({ error });
          setAppError(error);
        } finally {
          setTriggerSignWallet(false);
        }
      };
      handleLogin();
    }

    // joyid
    if (triggerSignWallet && joyIdAddress) {
      const handleLogin = async () => {
        history.push('/');
        resetAuth();
        try {
          const signInMessage: any = await getSignInMessage({ type: 'evm', address: joyIdAddress });
          const signature = await joyIdSignTypedData(signInMessage, joyIdAddress);
          login({ type: 'evm', address: joyIdAddress, signature })
            .then(() => {
              history.push(redirectPath === '/' ? '/campaigns' : redirectPath);
            })
            .finally(() => {
              setTriggerSignWallet(false);
            });
        } catch (e) {
          const error = e as AppError;
          Logger.error({ error });
          setAppError(error);
        } finally {
          setTriggerSignWallet(false);
        }
      };
      handleLogin();
    }

    // okx
    if (triggerSignWallet && okxProvider && okxAddress) {
      const handleLogin = async () => {
        history.push('/');
        resetAuth();
        try {
          const signInMessage: any = await getSignInMessage({ type: 'evm', address: okxAddress });
          const signature = await signTypedData(okxProvider, okxAddress, signInMessage);
          login({ type: 'evm', address: okxAddress, signature })
            .then(() => {
              history.push(redirectPath === '/' ? '/campaigns' : redirectPath);
            })
            .finally(() => {
              setTriggerSignWallet(false);
            });
        } catch (e) {
          const error = e as AppError;
          Logger.error({ error });
          setAppError(error);
        } finally {
          setTriggerSignWallet(false);
        }
      };
      handleLogin();
    }
  }, [triggerSignWallet, provider, userAddress, joyIdAddress, okxAddress, okxProvider]);

  const contexts = {
    selectWallet,
    setSelectWallet,
    web3,
    currentChainType: 'evm',
    isMainnet,
    triggerSignWallet,
    setTriggerSignWallet,
    appError,
    setAppError,
    deactivateWallet,
    handleMetamaskSwitchNetwork,
    handleOKXSwitchNetwork,
    handleJoyIdSwitchNetwork,
    isJoyIdConnected,
    setIsJoyIdConnected,
    redirectPath,
  };

  return <ApplicationContext.Provider value={contexts}>{children}</ApplicationContext.Provider>;
};

const useApplicationContext = () => useContext(ApplicationContext);

const ApplicationProvider = Provider;

export { ApplicationProvider };

export default useApplicationContext;
