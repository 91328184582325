import React from 'react';
import { CustomMuiButton } from './Styles';

type Props = {
  name?: string;
  onClick?: () => void;
};

const ConnectButton = ({ name = undefined, onClick = () => {}, ...props }: Props) => (
  <CustomMuiButton {...props} onClick={onClick}>
    {name}
  </CustomMuiButton>
);

export default ConnectButton;
