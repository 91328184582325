import styled from 'styled-components';
import { Row, fontStyle, size } from '@styles/common';
import { imageErrorIcon, whiteTriangleIcon } from '@styles/imgMapping';
import { SM_AND_UP_QUERY } from '@/breakpoints';

export const StyledBackground = styled(Row)`
  flex-direction: column;
  position: relative;
  background: url(${imageErrorIcon});
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  ${size('100vw', '100vh')};
`;

export const StyledContent = styled.div`
  width: 280px;
  padding: 40px;
  background: ${({ theme }) => theme.colors.white};
  text-align: center;
  word-break: break-all;

  @media ${SM_AND_UP_QUERY} {
    position: relative;
    z-index: 1;
    background: transparent;
  }
`;

export const StyledTitle = styled.div`
  ${fontStyle('58px', '700')};
  margin-bottom: 16px;
`;

export const StyledMessage = styled.div`
  ${fontStyle('16px')};
  min-height: 105px;
  margin-bottom: 16px;
`;

export const StyledButton = styled.div`
  ${size('200px', 'auto')};
  ${fontStyle('16px')};
  padding: 12px 0;
  background: ${({ theme }) => theme.colors.darkDream};
  color: #ffec00;
  border-radius: 4px;
`;

export const StyledWhiteTriangle = styled.div`
  ${size('100%', '100%')};
  background-image: url(${whiteTriangleIcon});
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
