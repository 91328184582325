import { Button as MButton } from '@mui/material';
import styled from 'styled-components';
import { size } from '@styles/common';

export enum ButtonType {
  CUSTOM = 'CUSTOM',
  OUTLINE = 'OUTLINE',
  GENERAL = 'GENERAL',
  DELETE = 'DELETE',
}

export type ButtonProps = {
  width?: string;
  height?: string;
  fontsize?: string;
  borderColor?: string;
  btnColor?: string;
  background?: string;
  hoverBtnColor?: string;
  hoverBackground?: string;
  mr?: string;
  ml?: string;
  mt?: string;
  mb?: string;
  buttonType: ButtonType;
};

export const StyledButton = styled(MButton)<ButtonProps>`
  &&& {
    font-family: 'Poppins', sans-serif;
    border-radius: 4px;
    font-weight: 500;
    text-transform: none;
    ${({ width, height }) => size(width || '50px', height || '50px')};
    font-size: ${({ fontsize }) => fontsize || '20px'};
    margin-right: ${({ mr }) => mr};
    margin-left: ${({ ml }) => ml};
    margin-bottom: ${({ mb }) => mb};
    margin-top: ${({ mt }) => mt};
    border: solid 1px
      ${({ theme, buttonType, borderColor }) => {
        switch (buttonType) {
          case ButtonType.CUSTOM:
            return borderColor;
          case ButtonType.DELETE:
            return theme.colors.rose;
          case ButtonType.OUTLINE:
            return theme.colors.brightDream;
          case ButtonType.GENERAL:
          default:
            return 'transparent';
        }
      }};
    color: ${({ theme, buttonType, btnColor }) => {
      switch (buttonType) {
        case ButtonType.CUSTOM:
          return btnColor;
        case ButtonType.DELETE:
          return theme.colors.rose;
        case ButtonType.OUTLINE:
          return theme.colors.brightDream;
        case ButtonType.GENERAL:
        default:
          return theme.colors.hope;
      }
    }};
    background: ${({ theme, buttonType, background }) => {
      switch (buttonType) {
        case ButtonType.CUSTOM:
          return background;
        case ButtonType.DELETE:
        case ButtonType.OUTLINE:
          return theme.colors.white;
        case ButtonType.GENERAL:
        default:
          return theme.colors.darkDream;
      }
    }};

    :hover {
      background: ${({ theme, buttonType, hoverBackground }) => {
        switch (buttonType) {
          case ButtonType.CUSTOM:
            return hoverBackground;
          case ButtonType.DELETE:
            return 'rgba(255, 44, 130, 0.05)';
          case ButtonType.OUTLINE:
            return 'rgba(177, 67, 255, 0.05)';
          case ButtonType.GENERAL:
          default:
            return theme.colors.brightDream;
        }
      }};
      color: ${({ theme, buttonType, hoverBtnColor }) => {
        switch (buttonType) {
          case ButtonType.CUSTOM:
          default:
            return hoverBtnColor;
        }
      }};
    }
  }
`;
