import styled from 'styled-components';
import { fontStyle } from '@styles/common';
import { SM_AND_UP_QUERY } from '@/breakpoints';

export const StyledContainer = styled.div`
  width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px 8px;
  padding: 0 12px;

  @media ${SM_AND_UP_QUERY} {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0;
    grid-gap: 32px 24px;
  }
`;

export const StyledTitle = styled.div`
  text-align: center;
  margin-top: 10px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.white};
  ${fontStyle('24px', '700')}
  line-height: 40px;
  font-family: 'Poppins', sans-serif;
  font-style: italic;

  @media ${SM_AND_UP_QUERY} {
    margin-top: 37px;
    margin-bottom: 12px;
    font-size: 36px;
    line-height: 72px;
  }
`;

export const StyledWarn = styled.div`
  font-size: 24px;
  margin-top: 48px;
  text-align: center;
  color: white;
`;
