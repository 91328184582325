import React from 'react';
import { getConfig, getConnectedAddress } from '@joyid/evm';

export const useJoyIdWallet = () => {
  const { network } = getConfig();

  const joyIdAddress = getConnectedAddress() || '';

  const joyIdchainId = network?.chainId || 1;

  return { joyIdAddress, joyIdchainId };
};
