import React, { useRef, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import useOnClickOutside from 'use-onclickoutside';
import { chainMapping } from '@configs/evmWalletConfigs';
import { Row, StyledMaskIcon } from '@styles/common';
import { zondiconsNetworkPurpleIcon } from '@styles/imgMapping';
import { useOKXWallet } from '@/hooks/useOKXWallet';
import { NetworkSwitchList } from '../NetworkSwitchList';
import { NetworkWrapper, StyledNetworkText } from './Styles';

type Props = {
  isTestnet: boolean;
};

const NetworkSelectButton = ({ isTestnet }: Props) => {
  const { chainId: metamaskChainId } = useWeb3React();
  const { okxChainId } = useOKXWallet();

  const chainId = metamaskChainId || okxChainId;

  const [openNetworkList, setOpenNetworkList] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(ref, () => setOpenNetworkList(false));

  return (
    <NetworkWrapper ref={ref}>
      <Row onClick={() => setOpenNetworkList(!openNetworkList)}>
        <StyledMaskIcon src={zondiconsNetworkPurpleIcon} width="20px" mr="12px" />
        <StyledNetworkText>{chainMapping[chainId as number]?.shortName || ''}</StyledNetworkText>
      </Row>
      <NetworkSwitchList isShow={openNetworkList} isTestnet={!!isTestnet} />
    </NetworkWrapper>
  );
};

export default NetworkSelectButton;
