import styled from 'styled-components';
import { fontStyle } from '@styles/common';
import { SM_AND_UP_QUERY } from '@/breakpoints';

export const StyledWrapper = styled.div`
  padding: 48px;
  margin-top: 32px;
  background: ${({ theme }) => theme.colors.black};
  border-top: 5px solid ${({ theme }) => theme.colors.loginWalletWrap};
  border-bottom: 5px solid ${({ theme }) => theme.colors.loginWalletWrap};

  @media ${SM_AND_UP_QUERY} {
    margin: 48px auto 0;
    width: 400px;
    border: 5px solid ${({ theme }) => theme.colors.loginWalletWrap};
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > button:last-child {
    margin-bottom: 0;
  }
`;

export const StyledTitle = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  ${fontStyle('20px', '700')};
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.white};

  @media ${SM_AND_UP_QUERY} {
    margin-bottom: 30px;
  }
`;
