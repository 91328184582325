import { auth, functions } from '@/firebaseConfig';
import { useState, useCallback } from 'react';
import { signInWithCustomToken } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';

function useLogin() {
  const [loading, setLoading] = useState(false);
  const loginCallable = useCallback((payload) => httpsCallable(functions, 'login')(payload), []);
  const authLogin = useCallback(
    async (payload) => {
      setLoading(true);
      try {
        const result = (await loginCallable(payload)).data;
        setLoading(false);
        await signInWithCustomToken(auth, result.data.accessToken);
        return Promise.resolve(result.data);
      } catch (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    },
    [loginCallable]);

  return {
    authLogin,
    loadingLogin: loading,
  };
}

export default useLogin;
