import isNil from 'lodash/isNil';
import styled from 'styled-components';
import { SM_AND_UP_QUERY, XS_QUERY } from '@/breakpoints';
import { ModalMode } from './type';

export const StyledBackDrop = styled.div<{ hasBackDrop?: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #333;
  opacity: ${({ hasBackDrop }) => (hasBackDrop ? 0.75 : 0)};
  z-index: 10;
  transition: opacity 0.3s;
`;

export const StyledContainer = styled.div<{
  width?: string | number;
  backgroundColor?: string;
  mode?: ModalMode;
}>`
  position: fixed;
  width: ${({ width }) => {
    if (!isNil(width)) {
      if (String(width).includes('%')) return width;
      return `${width}px`;
    }
    return '100%';
  }};
  height: ${({ mode }) => {
    switch (mode) {
      case ModalMode.QR_CODE:
        return '100%';
      default:
        return '';
    }
  }};
  max-height: ${({ mode }) => {
    switch (mode) {
      case ModalMode.QR_CODE:
        return '100%';
      default:
        return '90%';
    }
  }};
  max-width: 100%;
  min-width: 300px;
  left: 50%;
  top: ${({ mode }) => {
    switch (mode) {
      case ModalMode.NORMAL:
        return '50%';
      case ModalMode.QR_CODE:
        return '60%';
      default:
        return '50%';
    }
  }};
  bottom: auto;
  right: auto;
  padding: 15px;
  transform: translate(-50%, -50%);
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-shadow: 0 0 10px rgba(100, 100, 100, 0.7);
  overflow-x: auto;
  overflow-y: auto;
  z-index: 15;
  border-radius: ${({ mode }) => {
    switch (mode) {
      case ModalMode.QR_CODE:
        return '20px 20px 0 0';
      default:
        return '';
    }
  }};

  @media ${XS_QUERY} {
    width: ${({ width }) => width || '90%'};
  }

  @media ${SM_AND_UP_QUERY} {
    width: ${({ width }) => {
      if (!isNil(width)) {
        if (String(width).includes('%')) return width;
        return `${width}px`;
      }
      return '500px';
    }};
    height: ${({ mode }) => {
      switch (mode) {
        case ModalMode.QR_CODE:
          return '489px';
        default:
          return '';
      }
    }};
    max-height: ${({ mode }) => {
      switch (mode) {
        case ModalMode.QR_CODE:
          return '';
        default:
          return '90%';
      }
    }};
    max-width: 100%;
    min-width: 300px;
    left: 50%;
    top: ${({ mode }) => {
      switch (mode) {
        case ModalMode.QR_CODE:
          return '50%';
        default:
          return '50%';
      }
    }};
    border-radius: ${({ mode }) => {
      switch (mode) {
        case ModalMode.QR_CODE:
          return '0';
        default:
          return '';
      }
    }};
    border: ${({ mode, theme }) => {
      switch (mode) {
        case ModalMode.QR_CODE:
          return `solid 5px ${theme.colors.brightDream}`;
        default:
          return '';
      }
    }};
  }
`;

export const StyledCloseButton = styled.div<{
  closeColor?: string;
  mode?: ModalMode;
}>`
  padding: 12px 15px;
  position: absolute;
  top: ${({ mode }) => {
    switch (mode) {
      case ModalMode.QR_CODE:
        return '13px';
      default:
        return '8px';
    }
  }};
  right: ${({ mode }) => {
    switch (mode) {
      case ModalMode.QR_CODE:
        return '15px';
      default:
        return '8px';
    }
  }};
  color: ${({ closeColor }) => closeColor};
  cursor: pointer;

  @media ${SM_AND_UP_QUERY} {
    font-size: 32px;
    top: ${({ mode }) => {
      switch (mode) {
        case ModalMode.QR_CODE:
          return '48px';
        default:
          return '8px';
      }
    }};
    right: ${({ mode }) => {
      switch (mode) {
        case ModalMode.QR_CODE:
          return '33px';
        default:
          return '8px';
      }
    }};
  }
`;
