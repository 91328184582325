import React from 'react';
import { useHistory } from 'react-router';
import { Footer } from '@components/Footer';
import { StyledDesktopRender } from '@styles/common';
import customConfig from '@styles/customConfig';
import {
  StyledBackground,
  StyledButton,
  StyledContent,
  StyledMessage,
  StyledTitle,
  StyledWhiteTriangle,
} from './Styles';

const ErrorPage = () => {
  const history = useHistory();

  const redirectToHome = () => {
    history.push('/');
  };

  return (
    <StyledBackground justify="center">
      <StyledContent>
        <StyledTitle>404</StyledTitle>
        <StyledMessage>PAGE NOT FOUND</StyledMessage>
        <StyledButton onClick={redirectToHome}>Home</StyledButton>
      </StyledContent>
      <StyledDesktopRender>
        <StyledWhiteTriangle />
      </StyledDesktopRender>
      <Footer customConfig={customConfig} />
    </StyledBackground>
  );
};

export default ErrorPage;
