import Button from '@mui/material/Button';
import styled from 'styled-components';
import { fontStyle } from '@styles/common';

export const CustomMuiButton = styled(Button)<{ width?: string; height?: string }>`
  &&& {
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || '52px'};
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.colors.black};
    border: 1px solid ${({ theme }) => theme.colors.darkDream};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.darkDream};
    ${fontStyle('16px', '700')}
    text-transform: none;
    box-shadow: 0px 1px 1px -2px rgba(0, 0, 0, 0.2);
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.12));

    :hover: {
      background: ${({ theme }) => theme.colors.hoverRefreshBtn},
    },
  }`;
