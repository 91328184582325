import { Logger } from '../api/logger';

export const apiUrl = process.env.REACT_APP_API_URL;

export const convertIPFS = (url = '', disableCache = false) => {
  let returnUrl = url;
  if (returnUrl.startsWith('ipfs://')) {
    returnUrl = returnUrl.replace('ipfs://', `${apiUrl}/api/v1/ipfs/`);
  }
  if (disableCache) {
    const timestamp = new Date().getTime();
    try {
      const urlObject = new URL(returnUrl);
      const { search } = urlObject;
      const searchParams = new URLSearchParams(search);
      searchParams.append('rpurge', String(timestamp));
      urlObject.search = searchParams.toString();
      returnUrl = urlObject.toString();
    } catch (e) {
      Logger.error({ error: e as Error });
    }
  }
  return returnUrl;
};
