import React, { createContext, useContext, useState } from 'react';
import Cookies from 'js-cookie';
import { useLogin } from '@api/auth/login';
import { Logger } from '@api/logger';

type Props = {
  children: React.ReactNode;
};

interface ILoginParams {
  type: string;
  address: string;
  signature: string;
}

interface ContextType {
  accessToken: string;
  login: (params: ILoginParams) => Promise<void>;
  resetAuth: () => void;
}

const defaultAuth = {
  accessToken: '',
};

const AuthContext = createContext<ContextType>({
  ...defaultAuth,
  login: () => Promise.resolve(),
  resetAuth: () => { },
});

const useAuthProvider = () => {
  const [auth, setAuth] = useState(defaultAuth);
  const { authLogin } = useLogin();

  const login = async ({ type, address, signature }: ILoginParams) => {
    try {
      const data = await authLogin({ type, address, signature });
      setAuth({
        accessToken: data.accessToken,
      });
    } catch (e) {
      Logger.error({ error: e as Error, message: 'login error' });
    }
  };

  const resetAuth = () => {
    Cookies.remove('token');
    Cookies.remove('refreshToken');

    setAuth(defaultAuth);
  };

  return {
    ...auth,
    login,
    resetAuth,
  };
};

export const AuthProvider = ({ children }: Props) => {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
