import React from 'react';
import { ConnectButton } from '@components/ConnectButton';
import { SelectWallet, connecterConfigs, connecterList } from '@configs/evmConnecters';
import useApplicationContext from '@providers/applicationContext';
import { AppError } from '@/providers/pageProvider';
import { StyledContainer, StyledTitle, StyledWrapper } from './Styles';

const BindingModal = () => {
  const { setSelectWallet, deactivateWallet, setAppError, setIsJoyIdConnected, redirectPath } = useApplicationContext();

  const handleMetamskMobile = () => {
    const ua = navigator.userAgent;
    const isIOS = /iphone|ipad|ipod|ios/i.test(ua);
    const isAndroid = /android|XiaoMi|MiuiBrowser/i.test(ua);
    const isMobile = isIOS || isAndroid;
    const isMetaMaskApp = /MetaMaskMobile/i.test(ua);
    const link = `${window.location.host}/${redirectPath}`;

    if (isMobile && !isMetaMaskApp) {
      try {
        window.open(`https://metamask.app.link/dapp/${link}`);
      } catch (error) {
        // cannot open
      }
    }
  };

  const handleOKXMobile = async () => {
    const ua = navigator.userAgent;
    const isIOS = /iphone|ipad|ipod|ios/i.test(ua);
    const isAndroid = /android|XiaoMi|MiuiBrowser/i.test(ua);
    const isMobile = isIOS || isAndroid;
    const isOKApp = /OKApp/i.test(ua);
    const link = `${window.location.host}/${redirectPath}`;

    if (isMobile && !isOKApp) {
      try {
        const encodedUrl = `https://www.okx.com/download?deeplink=${encodeURIComponent(
          `okx://wallet/dapp/url?dappUrl=${encodeURIComponent(link)}`,
        )}`;
        window.open(encodedUrl);
      } catch (error) {
        // cannot open
      }
    }
  };

  return (
    <StyledWrapper>
      <StyledTitle>Connect with wallet</StyledTitle>
      <StyledContainer>
        {connecterList.map((key) => {
          const { name, active } = connecterConfigs[key];
          return (
            <ConnectButton
              key={key}
              name={name}
              onClick={async () => {
                if (name === SelectWallet.META_MASK) {
                  handleMetamskMobile();
                }
                if (name === SelectWallet.OKX) {
                  handleOKXMobile();
                }

                try {
                  await deactivateWallet();
                  setSelectWallet(name as SelectWallet);
                  await active();

                  if (name === SelectWallet.JOY_ID) {
                    setIsJoyIdConnected(true);
                  }
                } catch (error) {
                  setAppError(error as AppError);
                }
              }}
            />
          );
        })}
      </StyledContainer>
    </StyledWrapper>
  );
};

export default BindingModal;
