import Button from '@mui/material/Button';
import styled from 'styled-components';
import { Row, fontEllipsis, fontStyle, size } from '@styles/common';
import { SM_AND_UP_QUERY } from '@/breakpoints';

export const StyledImage = styled.img<{ objectFit: string }>`
  ${size('140px', '100%')};
  object-fit: ${({ objectFit }) => objectFit || 'cover'};
  border-radius: 8px 0 0 8px;

  @media ${SM_AND_UP_QUERY} {
    ${size('100%', '200px')};
    border-radius: 8px 8px 0 0;
  }
`;

export const StyledVideo = styled.video`
  width: 100%;
`;

export const StyledWrapper = styled(Row)`
  ${size('100%', '140px')};
  position: relative;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.darkDream};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  justify-content: space-between;
  margin-bottom: 16px;

  @media ${SM_AND_UP_QUERY} {
    ${size('auto', 'auto')};
    flex-direction: column;
    margin-bottom: 0px;
  }
`;

export const StyledContent = styled.div`
  padding: 16px;
  width: 100%;
`;

export const StyledNftTitle = styled.div`
  height: 34px;
  word-break: break-all;
  ${fontEllipsis(2)};
  ${fontStyle('14px', '700')};
  color: ${({ theme }) => theme.colors.darkDream};

  @media ${SM_AND_UP_QUERY} {
    height: 38px;
    ${fontStyle('16px', '700')};
    color: ${({ theme }) => theme.colors.darkDream};
  }
`;

export const StyledTokenId = styled.div`
  ${fontStyle('12px')};
  margin: 10px 0;
  color: ${({ theme }) => theme.colors.white};

  @media ${SM_AND_UP_QUERY} {
    ${fontStyle('14px')};
    margin: 8px 0 23px 0;
  }
`;

export const StyledRedeemButton = styled(Button)<{ isRedeemable: boolean }>`
  &&& {
    ${size('100%', '41px')};
    ${fontStyle('14px', '700')};
    padding: 12px 0;
    background-color: ${({ theme, isRedeemable }) => (isRedeemable ? 'rgba(0, 0, 0, 0.6)' : theme.colors.darkDream)};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.white};
    text-transform: none;
    box-shadow: 0px 1px 1px -2px rgba(0, 0, 0, 0.2);
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.12));

    :hover {
      background-color: ${({ theme }) => theme.colors.brightDream};
    }

    @media ${SM_AND_UP_QUERY} {
      ${size('168px', '43px')};
      ${fontStyle('16px', '700')};
    }
  }
`;

export const StyledInValidBackgroud = styled.div`
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
`;
