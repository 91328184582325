import { useCallback, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '@/firebaseConfig';

export interface IGetRedeemMessagePayload {
  type: string;
  chainId: number;
  contractAddress: string;
  tokenId: number;
  utilityCollectionId: string;
  address: string;
}

export interface IGetRedeemMessageResponse {
  message: string;
  types: string;
  domian: string;
  primaryType: string;
}

function useGetRedeemMessage() {
  const [loading, setLoading] = useState(false);
  const getRedeemMessageCallable = useCallback((params: IGetRedeemMessagePayload) => httpsCallable(functions, 'getRedeemMessage')(params), []);
  const getRedeemMessage = useCallback(
    async (payload: IGetRedeemMessagePayload): Promise<IGetRedeemMessageResponse> => {
      setLoading(true);
      try {
        const response: any = (await getRedeemMessageCallable(payload)).data;
        setLoading(false);
        const data = response.data as IGetRedeemMessageResponse;
        return Promise.resolve(data);
      } catch (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    },
    [getRedeemMessageCallable],
  );

  return {
    getRedeemMessage,
    loadingGetRedeemMessage: loading,
  };
}

export default useGetRedeemMessage;
