import { useCallback, useState } from 'react';
import { functions } from '@/firebaseConfig';
import { httpsCallable } from 'firebase/functions';

export interface IGetUtilityCollectionsPayload {
  type: string,
  chainId: number,
  uuid?: string;
}

export interface IUtilityCollections {
  id: string;
  name: string;
  description: string;
  redeemedDescription: string;
  imageUrl: string;
  contractAddresses: string[];
  startTime: string;
  endTime: string
}

export interface IUtilityCollectionById {
  id: string;
  name: string;
  description: string;
  mediaUrls: {
    image: string;
    video: string;
  };
  startTime: string;
  endTime: string;
}

function useGetUtilityCollections() {
  const [loading, setLoading] = useState(false);
  const getUtilityCollectionsCallable = useCallback((params: IGetUtilityCollectionsPayload) => httpsCallable(functions, 'getUtilityCollections')(params), []);
  const getUtilityCollections = useCallback(
    async (payload: IGetUtilityCollectionsPayload): Promise<IUtilityCollections | IUtilityCollectionById[]> => {
      setLoading(true);
      try {
        const response: any = (await getUtilityCollectionsCallable(payload)).data;
        setLoading(false);
        const data = response.data as IUtilityCollections | IUtilityCollectionById[];
        return Promise.resolve(data);
      } catch (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    },
    [getUtilityCollectionsCallable],
  );

  return {
    getUtilityCollections,
    loadingGetUtilityCollections: loading,
  };
}

export default useGetUtilityCollections;
