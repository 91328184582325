import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routeConfigs from './config';

const Router = () => (
  <Switch>
    {routeConfigs.map((route) => (
      <Route key={route.id} exact path={route.path} render={route.component} />
    ))}
    <Redirect to="/" />
  </Switch>
);

export { routeConfigs };
export default Router;
