import { StyledMaskIcon } from '@styles/common';
import { refreshIcon } from '@styles/imgMapping';
import React from 'react';
import { CustomMuiButton } from './Styles';

type Props = {
  width?: string;
  height?: string;
  iconWidth?: string;
  onRefresh: () => void;
};

const RefreshButton = ({ width, height, iconWidth, onRefresh }: Props) => (
  <CustomMuiButton width={width} height={height} onClick={onRefresh}>
    <StyledMaskIcon src={refreshIcon} width={iconWidth || '24px'} mr="12px" />
    Refresh
  </CustomMuiButton>
);

export default RefreshButton;
