import React from 'react';
import { gridiconsNoticeIcon } from '@styles/imgMapping';
import { StyledWarningImage, StyledWrapper } from './Styles';

const EventIsFull = () => (
  <StyledWrapper>
    <StyledWarningImage src={gridiconsNoticeIcon} />
    <div>The event is full.</div>
  </StyledWrapper>
);

export default EventIsFull;
