import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { StyledMaskIcon } from '@styles/common';
import { unbindingIcon } from '@styles/imgMapping';
import useApplicationContext from '@/providers/applicationContext';
import useNavbarContext from '../navbarContext';
import { StyledWalletAddress, StyledWalletAddressWrapper } from './Styles';

const UnbindAddressButton = () => {
  const { walletAddress: customWalletAddr, onResetAuth } = useNavbarContext();
  const { deactivateWallet } = useApplicationContext();
  const { account } = useWeb3React();

  const handleUnbind = async () => {
    await deactivateWallet();
    onResetAuth();
  };

  const convertAddressToSimple = (address: string) => {
    const prefixStr = address.substring(0, 6);
    const suffixStr = address.substring(address.length - 4);
    return `${prefixStr}...${suffixStr}`;
  };

  const getAddress = () => {
    if (customWalletAddr) return customWalletAddr;
    return account;
  };

  const walletAddress = convertAddressToSimple(getAddress() || '');

  return (
    <StyledWalletAddressWrapper onClick={handleUnbind}>
      <StyledMaskIcon src={unbindingIcon} width="34px" />
      <StyledWalletAddress>{walletAddress}</StyledWalletAddress>
    </StyledWalletAddressWrapper>
  );
};

export default UnbindAddressButton;
