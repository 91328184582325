import { useCallback, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '@/firebaseConfig';
import { IRedemption, IRedeemPayload } from './type';

function useRedeem() {
  const [loading, setLoading] = useState(false);
  const redeemCallable = useCallback((params: IRedeemPayload) => httpsCallable(functions, 'redeem')(params), []);
  const redeem = useCallback(
    async (payload: IRedeemPayload): Promise<IRedemption> => {
      setLoading(true);
      try {
        const response: any = (await redeemCallable(payload)).data;
        setLoading(false);
        const data = response.data as IRedemption;
        return Promise.resolve(data);
      } catch (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    },
    [redeemCallable],
  );

  return {
    redeem,
    loadingRedeem: loading,
  };
}

export default useRedeem;
