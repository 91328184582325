import styled, { createGlobalStyle } from 'styled-components';
import { SM_AND_UP_QUERY } from './breakpoints';

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
`;

export const StyledDesktopLayoutContent = styled.div`
  @media ${SM_AND_UP_QUERY} {
    max-width: 1228px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }
`;
