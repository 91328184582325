import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { utilityCollectionEndIcon } from '@styles/imgMapping';
import {
  StyledUtilityCollectionEndIcon,
  StyledContainer,
  StyledContent,
  StyledDateDesktop,
  StyledDateMobile,
  StyledImageContainer,
  StyledTitle,
} from './Styles';

type Props = {
  name: string;
  imageUrl: string;
  startTime: string;
  endTime: string;
  loading: boolean;
  defaultImg?: string;
  onClick?: () => void;
};

const UtilityCollectionCard = ({
  name = '',
  imageUrl = '',
  startTime = new Date().toISOString(),
  endTime = new Date().toISOString(),
  loading,
  defaultImg,
  onClick,
}: Props) => {
  const [height, setHeight] = useState<number>(0);
  const imageRef = useRef<HTMLImageElement | null>(null);

  const handleClick = () => {
    if (loading) return;
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  useEffect(() => {
    if (imageRef.current) {
      const { width } = imageRef.current.getBoundingClientRect();
      setHeight(width);
    }
  }, []);
  const formatStartTime = moment(startTime).format('YYYY/M/D HH:mm');
  const formatEndTime = moment(endTime).format('YYYY/M/D HH:mm');
  const isUtilityCollectionEnd = moment().isAfter(endTime);

  return (
    <StyledContainer role="presentation" onClick={handleClick}>
      <StyledImageContainer height={height}>
        <img
          ref={imageRef}
          height={height}
          alt="cover"
          src={imageUrl || defaultImg || ''}
          style={{ width: '100%', objectFit: 'contain' }}
        />
        {isUtilityCollectionEnd && <StyledUtilityCollectionEndIcon src={utilityCollectionEndIcon} alt="utilityCollection_end" />}
      </StyledImageContainer>
      <StyledContent>
        <StyledDateMobile>{`${formatStartTime} ~ ${formatEndTime}`}</StyledDateMobile>
        <StyledDateDesktop>{`${formatStartTime} ~ ${formatEndTime}`}</StyledDateDesktop>
        <StyledTitle>{name}</StyledTitle>
      </StyledContent>
    </StyledContainer>
  );
};

export default UtilityCollectionCard;
