import React from 'react';
import { okxHooks } from '@configs/evmConnecters';

export const useOKXWallet = () => {
  const okxChainId = okxHooks.useChainId();
  const okxAddress = okxHooks.useAccount();
  const okxIsActive = okxHooks.useIsActive();
  const okxProvider = okxHooks.useProvider();

  return { okxChainId, okxAddress, okxIsActive, okxProvider };
};
