import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { StyledBackDrop, StyledCloseButton, StyledContainer } from './Styles';
import { ModalMode } from './type';

type Props = {
  hasBackDrop?: boolean;
  backgroundColor?: string;
  closeColor?: string;
  opened?: boolean;
  onClose?: () => void;
  width?: string | number;
  children: React.ReactNode;
  mode?: ModalMode;
  disabledCloseButton?: boolean;
};

let modalNode: any;

if (typeof window !== 'undefined') {
  modalNode = document.createElement('div');
  modalNode.classList.add('modal-container');
  document.body.appendChild(modalNode);
}

const Modal = ({
  hasBackDrop = true,
  backgroundColor = '#fff',
  closeColor = '#000',
  opened = false,
  onClose = () => {},
  width,
  children,
  mode,
  disabledCloseButton,
  ...rest
}: Props) => {
  useEffect(() => {
    function handleEscapeKey(event: KeyboardEvent) {
      if (event.code === 'Escape') {
        onClose();
      }
    }

    document.addEventListener('keydown', handleEscapeKey);
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, []);
  return (
    opened &&
    modalNode &&
    createPortal(
      <div {...rest}>
        <StyledBackDrop hasBackDrop={hasBackDrop} onClick={onClose} />
        <StyledContainer width={width} backgroundColor={backgroundColor} mode={mode}>
          {!disabledCloseButton && (
            <StyledCloseButton closeColor={closeColor} onClick={onClose} mode={mode}>
              &#x2715;
            </StyledCloseButton>
          )}
          {children}
        </StyledContainer>
      </div>,
      modalNode,
    )
  );
};

export default Modal;
