import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Logger } from '@api/logger';
import { Footer, Mode } from '@components/Footer';
import { Navbar } from '@components/Navbar';
import { Mode as NavbarMode } from '@components/Navbar/type';
import { getSwitchNetworks } from '@configs/evmWalletConfigs';
import customConfig from '@styles/customConfig';
import { WebsiteBgImage } from '@styles/imgMapping';
import { ErrorModal } from '@/components/ErrorModal';
import { useJoyIdWallet } from '@/hooks/useJoyIdWallet';
import { useOKXWallet } from '@/hooks/useOKXWallet';
import useApplicationContext from '@/providers/applicationContext';
import { useAuthContext } from '@/providers/authProvider';
import { AppError, PageProvider, usePageContext } from '@/providers/pageProvider';
import { StyledDesktopLayoutContent } from '../globalStyle';

const StyledContainer = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  background-color: ${({ theme }) => theme.colors.brainGrey};
  min-height: 100vh;
  position: relative;
  background: url(${WebsiteBgImage});
  background-color: ${({ theme }) => theme.colors.brightDream};
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledAutoSpace = styled.div`
  flex: 1;
  width: 100%;
`;

type Props = {
  children: React.ReactNode;
};

const Page = ({ children }: Props) => {
  const { account } = useWeb3React();
  const { okxAddress } = useOKXWallet();
  const { joyIdAddress } = useJoyIdWallet();

  const { isMainnet, currentChainType } = useApplicationContext();
  const { accessToken, resetAuth } = useAuthContext();
  const { error, updateError } = usePageContext();

  const history = useHistory();

  const address = account || okxAddress || joyIdAddress;

  const onRedirectToHome = () => {
    if (account && accessToken) {
      history.push('/campaigns');
    } else {
      history.push('/');
    }
  };

  const onLogError = (error: Error, account?: string | null) => {
    Logger.error({
      error,
      message: `Switch network error with address: ${account}`,
    });
  };

  const handleLogout = async () => {
    resetAuth();
  };

  return (
    <StyledContainer>
      <StyledAutoSpace>
        <Navbar
          walletAddress={joyIdAddress || okxAddress}
          isMainnet={isMainnet}
          currentChainType={currentChainType}
          onResetAuth={handleLogout}
          onLogoClick={onRedirectToHome}
          onSwitchChainRedirect={onRedirectToHome}
          switchNetworks={getSwitchNetworks()}
          onLogError={onLogError}
          mode={NavbarMode.DARK}
        />
        <StyledDesktopLayoutContent>{children}</StyledDesktopLayoutContent>
      </StyledAutoSpace>
      <Footer forSales mode={Mode.DARK} customConfig={customConfig} />
      <ErrorModal title="Error" opened={!!error} onClose={() => updateError(undefined)} error={error as AppError} />
    </StyledContainer>
  );
};

const PageWithProvider = (props: Props) => {
  const { children } = props;
  return (
    <PageProvider>
      <Page {...props}>{children}</Page>
    </PageProvider>
  );
};

export default PageWithProvider;
