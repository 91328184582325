import styled from 'styled-components';
import { Modal } from '@components/Modal';
import { Row, fontStyle, size } from '@styles/common';
import { SM_AND_UP_QUERY } from '@/breakpoints';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 9px;
  background: ${({ theme }) => theme.colors.black};

  @media ${SM_AND_UP_QUERY} {
    height: 100%;
  }
`;

export const StyledQrCodeWrapper = styled.div`
  ${size('220px', '220px')};
  margin-top: 45px;
  position: relative;
`;

export const StyledQrCode = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  ${size('100%', '100%')};
  border: solid 5px ${({ theme }) => theme.colors.white};
`;

export const StyledRefreshBackground = styled(Row)`
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  left: 0;
  top: 0;
  ${size('100%', '100%')};
`;

export const StyledTitle = styled.div<{ hideMargin?: boolean }>`
  ${fontStyle('20px', '700')};
  margin-bottom: 16px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  margin-top: 45px;
  color: ${({ theme }) => theme.colors.white};

  @media ${SM_AND_UP_QUERY} {
    margin-top: ${({ hideMargin }) => hideMargin && 0};
  }
`;

export const StyledDescription = styled.div`
  ${fontStyle('14px')};
  font-family: 'Inter', sans-serif;
  word-break: break-word;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};

  a {
    color: #faff00;
  }
`;

export const StyledModal = styled(Modal)`
  &&& {
    & > div:last-child {
      background: ${({ theme }) => theme.colors.black};
    }

    @media ${SM_AND_UP_QUERY} {
      & > div:last-child {
        border: solid 5px ${({ theme }) => theme.colors.darkDream};
        background: ${({ theme }) => theme.colors.black};
      }
    }
  }
`;

export const StyledQrStringWrapper = styled.div`
  margin-top: 101px;
  margin-bottom: 32px;
  position: relative;
  word-break: break-word;

  a {
    color: #faff00;
  }

  @media ${SM_AND_UP_QUERY} {
    margin-top: 0;
  }
`;
