import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from './globalStyle';
import { ApplicationProvider } from './providers/applicationContext';
import { AuthProvider } from './providers/authProvider';
import Router from './routes';
import theme from './styles/theme';

const App = () => (
  <BrowserRouter>
    <AuthProvider>
      <ApplicationProvider>
        <ThemeProvider theme={theme}>
          <Router />
          <GlobalStyle />
        </ThemeProvider>
      </ApplicationProvider>
    </AuthProvider>
  </BrowserRouter>
);

export default App;
