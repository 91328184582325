import { Method } from '../promise-request';

const reviewUrl = process.env.REACT_APP_REVIEW_APP_URL as string;
const ticketBoxUrl = process.env.REACT_APP_TICKET_BOX_URL as string;
const nftStoreUrl = process.env.REACT_APP_NFT_STORE_URL as string;

export type Params = {
  utilityUri: string;
  address: string;
};

const caller = (data: Params) => {
  const { utilityUri, address } = data;

  let url = '';
  if (utilityUri.startsWith(reviewUrl)) {
    url = utilityUri.replace(`${reviewUrl}/api/v1`, '/review');
  } else if (utilityUri.startsWith(ticketBoxUrl)) {
    url = utilityUri.replace(`${ticketBoxUrl}/api/v1`, '/ticketbox');
  } else if (utilityUri.startsWith(nftStoreUrl)) {
    url = utilityUri.replace(`${nftStoreUrl}/v1`, '/nftstore');
  } else {
    url = utilityUri;
  }

  return {
    method: Method.GET,
    url,
    data: {
      address,
    },
  };
};

export default caller;
