export interface UtilityDataResponse {
  type: string;
  value: string;
  validated: boolean;
}

export enum UtilityType {
  QR_CODE = 'qrcode',
  STRING = 'string',
}
