import { initConfig } from '@joyid/evm';
import { chains } from './evmWalletConfigs';

export const initJoyIdByPolygon = () => {
  const isApplicationMainnet = (process.env.REACT_APP_NETWORK || 'TESTNET') === 'MAINNET';
  const network = isApplicationMainnet ? 'mainnet' : 'testnet';
  const config = chains.find((c) => c.network === network && c.name === 'polygon');
  const joyidAppURL = isApplicationMainnet ? 'https://app.joy.id' : 'https://testnet.joyid.dev';

  initConfig({
    name: 'RE:DREAMER',
    logo: 'https://passport.redreamer.io/favicon.svg',
    network: {
      chainId: config!.id,
      name: config!.name,
    },
    rpcURL: config!.rpc,
    joyidAppURL,
  });
};

export const initJoyIdByConflux = () => {
  const isApplicationMainnet = (process.env.REACT_APP_NETWORK || 'TESTNET') === 'MAINNET';
  const network = isApplicationMainnet ? 'mainnet' : 'testnet';
  const config = chains.find((c) => c.network === network && c.name.includes('Conflux'));
  const joyidAppURL = isApplicationMainnet ? 'https://app.joy.id' : 'https://testnet.joyid.dev';

  initConfig({
    name: 'RE:DREAMER',
    logo: 'https://passport.redreamer.io/favicon.svg',
    network: {
      chainId: config!.id,
      name: config!.name,
    },
    rpcURL: config!.rpc,
    joyidAppURL,
  });
};
