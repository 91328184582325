import React from 'react';
import { ButtonProps, StyledButton } from './Styles';

type Props = ButtonProps & {
  onClick?: () => void;
  ref?: any;
  type?: 'button' | 'submit' | 'reset';
  children: React.ReactNode;
};

const Button = ({ children, ref, type, onClick, ...props }: Props) => (
  <StyledButton ref={ref} type={type} {...props} onClick={onClick}>
    {children}
  </StyledButton>
);

export default Button;
