import { IGetRedeemMessageResponse } from '../getRedeemMessage/useGetRedeemMessage';

export interface IRedeemPayload {
  type: string;
  redeemMessage: IGetRedeemMessageResponse;
  signature: string;
}

export enum AfterRedeemedType {
  QR_CODE = 0,
  NONE = 1,
  QR_STRING = 2,
}

export interface IRedemption {
  utilityUri: string;
}
