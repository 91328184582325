import isNil from 'lodash/isNil';
import { axiosInstance as axios } from './axios.config';

interface ISendLogParams {
  error?: string;
  message?: string;
  level: 'error' | 'warn';
}

interface IErrorParams {
  error?: Error;
  message?: string;
}

interface IWarnParams {
  message: string;
}

const isProd = process.env.NODE_ENV === 'production';
export class Logger {
  static sendLog({ level = 'warn', error, message = '' }: ISendLogParams) {
    if (!isProd) return;
    axios({
      method: 'post',
      url: '/log',
      baseURL: '/',
      data: {
        level,
        message,
        error,
        type: 'client',
        userAgent: (window.navigator || {}).userAgent || 'unknown',
        timestamp: new Date().getTime(),
      },
    });
  }

  static error({ error, message }: IErrorParams) {
    let parsedError;
    if (error instanceof Error) {
      parsedError = error.toString();
    } else if (!isNil(error)) {
      parsedError = JSON.stringify(error);
    }
    Logger.sendLog({ level: 'error', error: parsedError, message });
  }

  static warn({ message }: IWarnParams) {
    Logger.sendLog({ level: 'warn', message });
  }
}
