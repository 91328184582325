import React from 'react';
import Lottie from 'lottie-react';
import styled from 'styled-components';
import { Row } from '@styles/common';
import defaultEffect from './loading.json';

const StyledContainer = styled(Row)<{ isLoading?: boolean }>`
  display: ${({ isLoading }) => (isLoading ? 'flex' : 'none')};
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
`;

type Props = {
  effect?: any;
  style?: any;
  isLoading: boolean;
};

const Loading = ({ effect, style, isLoading }: Props) => {
  if (!isLoading) return null;

  return (
    <StyledContainer justify="center" isLoading={isLoading}>
      <Lottie
        animationData={effect || defaultEffect}
        loop
        style={style || { width: 103, height: 103 }}
      />
    </StyledContainer>
  );
};

export default Loading;
