import { useState, useCallback } from 'react';
import { functions } from '@/firebaseConfig';
import { httpsCallable } from 'firebase/functions';

function useGetSignInMessage() {
  const [loading, setLoading] = useState(false);
  const getSignInMessageCallable = useCallback((payload) => httpsCallable(functions, 'getSignInMessage')(payload), []);
  const getSignInMessage = useCallback(
    async (payload) => {
      setLoading(true);
      try {
        const result = (await getSignInMessageCallable(payload)).data;
        setLoading(false);
        return Promise.resolve(result.data);
      } catch (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    },
    [getSignInMessageCallable]);

  return {
    getSignInMessage,
    loadingGetNonce: loading,
  };
}

export default useGetSignInMessage;
